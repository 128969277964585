import isInViewport from "./isInViewport.js"
import productCard from "../components/productCard.js"
import { arraysShareElements } from "../../utils/utilities.js";

export default ({
    categories = undefined,
    brands = undefined,
    products = undefined,
    container = document.querySelector(".product-cards"),
    load = 0,
    offsetPercent = 50,
    fireOnInit = false,
    sort = "new",
} = {}) => {
    let lastY = window.scrollY;
    
    // on init remove the 20 pre-rendered products from the container
    // for future etu-ohjelma and asiakashinta products
    // incase of discounts for logged in customers
    
    // ONLY IF LOGGED IN...??
    document.querySelector(".product-cards").innerHTML = "<template></template>";

    const addProductsToDOM = () => {
        if(!isInViewport(container, offsetPercent)) return
        let filteredProducts = products;
        if (brands) {
            filteredProducts = products.filter(item => brands.includes(item.brand));
        }
        if(categories){
            filteredProducts = products.filter(item => arraysShareElements(item.categories, categories))
        }
        // const categoriesFilteredProducts = categories ? products.filter(item => arraysShareElements(item.categories, categories)) : products;
        if (filteredProducts.length > load) {
            const addedProduct = filteredProducts.filter((_, i) => i === load).map(product => productCard(product));
            container.querySelector("template").outerHTML = addedProduct[0] + "<template></template>";
            // container.innerHTML += addedProduct[0]
            load += 1;
            addProductsToDOM()
        } else {
            removeEventListener();
        }
    }

    const isScrollingDownFetchProductsAndProcess = () => {
        if(!products){
            products = []
            fetch("/api/device-cards.json")
                .then(data => data.json())
                .then(data => {
                    products = data
                    addProductsToDOM()
                })
        }else if(products.length){
            addProductsToDOM()
        }
    }

    const removeEventListener = () => window.removeEventListener("scroll", handleScroll);

    const handleScroll = () => {
        // only interested when scrolling down...
        const Y = window.scrollY;
        if (Y > lastY && isInViewport(container, offsetPercent)) {
            isScrollingDownFetchProductsAndProcess()
        }
        lastY = Y;
    }

    // if the user refreshes the page, whilst they are at the bottom
    if (isInViewport(container, offsetPercent)) {
        isScrollingDownFetchProductsAndProcess()
    }

    // remove any previous lazyLoader
    window.dispatchEvent(new Event('removeLazyloadProductsListener'));
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("removeLazyloadProductsListener", removeEventListener);

};